<template>
    <div class="press-container">
        <h1 class="has-text-primary has-text-weight-bold" :class="displayOnTouch ? 'is-size-huge-mobile' : 'is-size-huge'">Presse</h1>
        <div class="columns is-multiline addMargin">
          
          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a target="_blank" href="http://www.radiojm.fr/emission/l-invite-d-elsa-3436" alt="Écouter l'interview sur RadioJM">
                    <img :src="require('./../../assets/press/RadioJM-header.jpg')" alt="RadioJM">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a target="_blank" href="http://www.radiojm.fr/emission/l-invite-d-elsa-3436" alt="Aller sur le site RadioJM">
                        <img :src="require('./../../assets/press/RadioJM-logo.png')" alt="RadioJM">
                      </a>
                    </figure>
                </div>
                <p>Marine était l'invitée d'Elsa de Radio JM</p>
                <a target="_blank" href="http://www.radiojm.fr/emission/l-invite-d-elsa-3436" alt="Écouter l'interview sur RadioJM" class="button  is-primary is-small">Écouter l'interview</a>
              </div>
            </div>
          </div>

          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a target="_blank" href="https://marseille.latribune.fr/innovation/2019-09-12/quel-business-modele-pour-je-stocke-mon-bateau-827970.html" alt="Lire l'article sur La Tribune">
                    <img :src="require('./../../assets/press/La-Tribune-header.jpg')" alt="La Tribune">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a target="_blank" href="https://marseille.latribune.fr/innovation/2019-09-12/quel-business-modele-pour-je-stocke-mon-bateau-827970.html" alt="Aller sur le site La Tribune">
                        <img :src="require('./../../assets/press/La-Tribune-logo.png')" alt="La Tribune">
                      </a>
                    </figure>
                </div>
                <p>Un article sur nous a été publié sur le journal La Tribune</p>
                <a target="_blank" href="https://marseille.latribune.fr/innovation/2019-09-12/quel-business-modele-pour-je-stocke-mon-bateau-827970.html" alt="Lire l'article sur La Tribune" class="button  is-primary is-small">Lire l'article</a>
              </div>
            </div>
          </div>
          
          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a target="_blank" href="https://www.lci.fr/population/les-bons-plans-de-la-rentree-2130880.html" alt="Lire l'article sur lci">
                    <img :src="require('./../../assets/press/lci-header.jpg')" alt="lci">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a target="_blank" href="https://www.lci.fr/population/les-bons-plans-de-la-rentree-2130880.html" alt="Aller sur le site lci">
                        <img :src="require('./../../assets/press/lci-logo.png')" alt="lci">
                      </a>
                    </figure>
                </div>
                <p>LCI parle de nous dans ses bons plans de la rentrée !</p>
                <a target="_blank" href="https://www.lci.fr/population/les-bons-plans-de-la-rentree-2130880.html" alt="Lire l'article sur lci" class="button  is-primary is-small">Lire l'article</a>
              </div>
            </div>
          </div>

          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a target="_blank" href="https://www.europe1.fr/emissions/la-france-bouge-avec-raphaelle-duchemin/raphaelle-duchemin-nautisme-ca-fourmille-dinnovations-pour-cet-ete-3908691" alt="Lire l'article sur europe1">
                    <img :src="require('./../../assets/press/europe1-header.jpg')" alt="europe1">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a target="_blank" href="https://www.europe1.fr/emissions/la-france-bouge-avec-raphaelle-duchemin/raphaelle-duchemin-nautisme-ca-fourmille-dinnovations-pour-cet-ete-3908691" alt="Aller sur le site europe1">
                        <img :src="require('./../../assets/press/europe1-logo.png')" alt="europe1">
                      </a>
                    </figure>
                </div>
                <p>Europe 1 dans l'émission "la France bouge" parle de nous.</p>
                <a target="_blank" href="https://www.europe1.fr/emissions/la-france-bouge-avec-raphaelle-duchemin/raphaelle-duchemin-nautisme-ca-fourmille-dinnovations-pour-cet-ete-3908691" alt="Lire l'article sur europe1" class="button  is-primary is-small">Lire l'article</a>
              </div>
            </div>
          </div>

          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a @click="isCardModalActive2=true" alt="Lire l'article">
                    <img :src="require('./../../assets/press/voileMagazine-header.jpg')" alt="Voile Magazine">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a @click="isCardModalActive2=true" alt="Lire l'article">
                        <img :src="require('./../../assets/press/voileMagazine-logo.png')" alt="Voile Magazine">
                      </a>
                    </figure>
                </div>
                <p>Voile Magazine<br>Lisez l'article paru dans le journal</p>
                <a class="button  is-primary is-small" @click="isCardModalActive2=true">          
                  Lire l'article
                </a>
              </div>
            </div>
          </div>

          <b-modal :active.sync="isCardModalActive2" :width="640" has-modal-card class="pressModal">
            <img :src="require('./../../assets/press/voileMagazine-modal.jpg')" alt="Voile Magazine">
          </b-modal>  


          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a @click="isCardModalActive=true" alt="Lire l'article">
                    <img :src="require('./../../assets/press/laProvence-header.jpg')" alt="La Provence">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a @click="isCardModalActive=true" alt="Lire l'article">
                        <img :src="require('./../../assets/press/laProvence-logo.png')" alt="La Provence">
                      </a>
                    </figure>
                </div>
                <p>La Provence<br>Lisez l'article paru dans le journal</p>
                <a class="button  is-primary is-small" @click="isCardModalActive=true">          
                  Lire l'article
                </a>
              </div>
            </div>
          </div>

          <b-modal :active.sync="isCardModalActive" :width="640" has-modal-card class="pressModal">
            <img :src="require('./../../assets/press/laProvenceModal.png')" alt="voileetmoteur">
          </b-modal>  

          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a target="_blank" href="https://www.voileetmoteur.com/bateaux-a-moteur/actualites/stocker-votre-bateau-en-quelques-cliques-avec-jestockemonbateau/82204" alt="Lire l'article sur voileetmoteur.com">
                    <img :src="require('./../../assets/press/voileetmoteur-header.jpg')" alt="voileetmoteur">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a target="_blank" href="https://www.voileetmoteur.com/bateaux-a-moteur/actualites/stocker-votre-bateau-en-quelques-cliques-avec-jestockemonbateau/82204" alt="Aller sur le site voileetmoteur.com">
                        <img :src="require('./../../assets/press/voileetmoteur-logo.png')" alt="voileetmoteur">
                      </a>
                    </figure>
                </div>
                <p>Voile et Moteur<br>Stocker votre bateau en quelques clics</p>
                <a target="_blank" href="https://www.voileetmoteur.com/bateaux-a-moteur/actualites/stocker-votre-bateau-en-quelques-cliques-avec-jestockemonbateau/82204" alt="Lire l'article sur voileetmoteur" class="button  is-primary is-small">Lire l'article</a>
              </div>
            </div>
          </div>

          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a target="_blank" href="https://voilesetvoiliers.ouest-france.fr/personnalite/economie/pour-stocker-son-bateau-a-terre-jestockemonbateau-fr-est-il-l-air-bnb-de-la-plaisance-4bb97652-9ce1-11e9-af4a-5f3fab29ec92?fbclid=IwAR0Q1u844WIWZVLjpjou4tfuEGnmTgUuyk49ubwa_VDEGIpX71iFTbD3_wI" alt="Lire l'article sur voiles et voiliers">
                    <img :src="require('./../../assets/press/voilesetvoiliers-header.jpg')" alt="voilesetvoiliers.ouest-france.fr">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a target="_blank" href="https://voilesetvoiliers.ouest-france.fr/personnalite/economie/pour-stocker-son-bateau-a-terre-jestockemonbateau-fr-est-il-l-air-bnb-de-la-plaisance-4bb97652-9ce1-11e9-af4a-5f3fab29ec92?fbclid=IwAR0Q1u844WIWZVLjpjou4tfuEGnmTgUuyk49ubwa_VDEGIpX71iFTbD3_wI" alt="Aller sur le site voilesetvoiliers.ouest-france.fr">
                        <img :src="require('./../../assets/press/voilesetvoiliers-logo.png')" alt="voilesetvoiliers.ouest-france.fr">
                      </a>
                    </figure>
                </div>
                <p>JeStockeMonBateau :<br>L’Airbnb du stockage de bateau ?</p>
                <a target="_blank" href="https://voilesetvoiliers.ouest-france.fr/personnalite/economie/pour-stocker-son-bateau-a-terre-jestockemonbateau-fr-est-il-l-air-bnb-de-la-plaisance-4bb97652-9ce1-11e9-af4a-5f3fab29ec92?fbclid=IwAR0Q1u844WIWZVLjpjou4tfuEGnmTgUuyk49ubwa_VDEGIpX71iFTbD3_wI" alt="Lire l'article sur voilesetvoiliers.ouest-france.fr" class="button  is-primary is-small">Lire l'article</a>
              </div>
            </div>
          </div>

          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a target="_blank" href="https://www.argusdubateau.fr/actualite/jestockemonbateau-le-stockage-partage" alt="Lire l'article sur l'argus du bateau">
                    <img :src="require('./../../assets/press/argusdubateau-header.jpg')" alt="argusdubateau.fr">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a target="_blank" href="https://www.argusdubateau.fr/actualite/jestockemonbateau-le-stockage-partage" alt="Aller sur le site argusdubateau.fr">
                        <img :src="require('./../../assets/press/argusdubateau-logo.png')" alt="argusdubateau.fr">
                      </a>
                    </figure>
                </div>
                <p>JeStockeMonBateau : le stockage partagé</p>
                <a target="_blank" href="https://www.argusdubateau.fr/actualite/jestockemonbateau-le-stockage-partage" alt="Lire l'article sur l'argus du bateau" class="button  is-primary is-small">Lire l'article</a>
              </div>
            </div>
          </div>

          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a target="_blank" href="https://actu.fr/bretagne/brest_29019/nautisme-stocker-bateau-chez-particulier_25688831.html" alt="Lire l'article sur Côté Brest">
                    <img :src="require('./../../assets/press/coteBrest-header.jpg')" alt="cotebrest.fr">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a target="_blank" href="https://actu.fr/bretagne/brest_29019/nautisme-stocker-bateau-chez-particulier_25688831.html" alt="Aller sur le site cotebrest.fr">
                        <img :src="require('./../../assets/press/coteBrest-logo.png')" alt="cotebrest.fr">
                      </a>
                    </figure>
                </div>
                <p>Nautisme : Stocker son bateau chez un particulier</p>
                <a target="_blank" href="https://actu.fr/bretagne/brest_29019/nautisme-stocker-bateau-chez-particulier_25688831.html" alt="Lire l'article sur Côté Brest" class="button  is-primary is-small">Lire l'article</a>
              </div>
            </div>
          </div>

          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a target="_blank" href="https://madeinmarseille.net/51328-je-stocke-mon-bateau-place-particulier/" alt="Lire l'article sur Made in Marseille">
                    <img :src="require('./../../assets/press/madeInMarseille-header.jpg')" alt="madeinmarseille.net">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a target="_blank" href="https://madeinmarseille.net/51328-je-stocke-mon-bateau-place-particulier/" alt="Aller sur le site madeinmarseille.net">
                        <img :src="require('./../../assets/press/madeInMarseille-logo.png')" alt="madeinmarseille.net">
                      </a>
                    </figure>
                </div>
                <p>Deux provençaux créent le Airbnb des places de bateau</p>
                <a target="_blank" href="https://madeinmarseille.net/51328-je-stocke-mon-bateau-place-particulier/" alt="Lire l'article sur Made in Marseille" class="button  is-primary is-small">Lire l'article</a>
              </div>
            </div>
          </div>
          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a target="_blank" href="https://www.breizh-info.com/2019/06/26/122137/jestockemonbateau-lairbnb-du-stockage-de-bateau" alt="Lire l'article sur Breizh info">
                    <img :src="require('./../../assets/press/breizhInfo-header.jpg')" alt="breizh-info.com">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a target="_blank" href="https://www.breizh-info.com/2019/06/26/122137/jestockemonbateau-lairbnb-du-stockage-de-bateau" alt="Aller sur le site breizh-info.com">
                        <img :src="require('./../../assets/press/breizhInfo-logo.png')" alt="breizh-info.com">
                      </a>
                    </figure>
                </div>
                <p>L’Airbnb du stockage de bateau par Breizh-info.com</p>
                <a target="_blank" href="https://www.breizh-info.com/2019/06/26/122137/jestockemonbateau-lairbnb-du-stockage-de-bateau" alt="Lire l'article sur Breizh info" class="button  is-primary is-small">Lire l'article</a>
              </div>
            </div>
          </div>
          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a target="_blank" href="https://www.lesechos.fr/pme-regions/innovateurs/jestockemonbateaufr-sattaque-a-la-saturation-des-ports-de-plaisance-1032040" alt="Lire l'article sur Breizh info">
                    <img :src="require('./../../assets/press/lesEchos-header.jpg')" alt="lesechos.fr">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a target="_blank" href="https://www.lesechos.fr/pme-regions/innovateurs/jestockemonbateaufr-sattaque-a-la-saturation-des-ports-de-plaisance-1032040" alt="Aller sur le site lesechos.fr">
                        <img :src="require('./../../assets/press/lesEchos-logo.png')" alt="lesechos.fr">
                      </a>
                    </figure>
                </div>
                <p>Le site Les Échos a publié un article sur jestockemonbateau.fr !</p>
                <a target="_blank" href="https://www.lesechos.fr/pme-regions/innovateurs/jestockemonbateaufr-sattaque-a-la-saturation-des-ports-de-plaisance-1032040" alt="Lire l'article sur Les Echos" class="button  is-primary is-small">Lire l'article</a>
              </div>
            </div>
          </div>
          <div class="column is-4-desktop is-4-tablet is-12-mobile">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <a target="_blank" href="https://www.boatindustry.fr/article/29630/stocker-bateau-une-alternative-collaborative-port-a-sec" alt="Lire l'article de Boatindustry">
                    <img :src="require('./../../assets/press/boatIndustry-header.jpg')" alt="Boatindustry.fr">
                  </a>
                </figure>
              </div>
              <div class="card-content has-text-centered">
                <div class="media">
                    <figure class="image centred">
                      <a target="_blank" href="https://www.boatindustry.fr/article/29630/stocker-bateau-une-alternative-collaborative-port-a-sec" alt="Aller sur le site Boatindustry">
                        <img :src="require('./../../assets/press/boatIndustry-logo.png')" alt="Boatindustry.fr">
                      </a>
                    </figure>
                </div>
                <p>Le site Boatindustry.fr parle de nous !</p>
                <br>
                <a target="_blank" href="https://www.boatindustry.fr/article/29630/stocker-bateau-une-alternative-collaborative-port-a-sec" alt="Lire l'article de Boatindustry" class="button  is-primary is-small">Lire l'article</a>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'StorageRules',
  data () {
    return {
      isCardModalActive:false,
      isCardModalActive2:false,
    }
  },
  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL,
      canonical = this.$router.resolve({ name: 'Press' });

    let title = (siteName+' - Stockage de bateaux entre Particuliers - stockage de bateaux chez les particuliers et professionnels - Hivernage Bateau - stockage bateau hors d\'eau'),
      description = siteName+' est une solution de stockage bateau sur mesure : le stockage bateau ou hivernage bateau entre particuliers (on parle aussi de « costockage »).';

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
        { rel: 'canonical', href: (baseUrl+canonical.href) },
      ],
      meta: [
        { 
          vmid: 'description', 
          name: 'description', 
          content: description 
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description,
          'vmid': 'og:description'
        },
        {
          'property': 'og:url',
          'content': (baseUrl+this.$route.fullPath),
          'vmid': 'og:url'
        },
        {
          'property': 'og:image',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:secure_url',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image:secure_url'
        },
        {
          'property': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'property': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
        {
          'property': 'og:image:alt',
          'content': title,
          'vmid': 'og:image:alt'
        },
        {
          'property': 'twitter:image',
          'content': (baseUrl+require('./../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .press-container {
    margin: 5% 30%;
  }
  .addMargin .column {
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
  .card {
    height: 100%;
  }
  p {
    text-align: left !important;
  }
  @media screen and (max-width: 2048px){
    .press-container {
      margin: 5% 25%;
    }
  }
  @media screen and (max-width: 1640px){
    .press-container {
      margin: 5% 20%;
    }
  }
  @media screen and (max-width: 1365px){
    .press-container {
      margin: 5% 15%;
    }
  }
  @media screen and (max-width: 1171px){
    .press-container {
      margin: 5% 10%;
    }
  }
  .press-container h1{
    padding-bottom: 5%;
  }
  .is-size-huge {
    font-size: 10rem;
    hyphens: auto;
  }
  .is-size-huge-mobile{
    font-size: 6rem;
  }
  h2 {
    line-height: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .break {
    word-break: break-all
  }
  p {
    margin-bottom: 1rem;
    text-align: justify;
  }
  @media screen and (max-width: 1087px) {
    .container {
      margin: 5%;
    }
  }
  .centred {
    margin: 0 auto;
  }
  @media screen and (max-width: 537px) {
    .container {
      margin: 5%;
    }
    .is-size-huge-mobile{
      font-size: 3rem;
      line-height: 3rem;
    }
  }
  .pressModal .animation-content {
      overflow-y: scroll;
      
  }
</style>
<style  lang="css">
  .pressModal .modal-background {
    z-index: -1 !important;
  }
    .pressModal .animation-content {
    z-index: 12 !important
  }
  @media screen and (min-width:320px) and (max-width:1049px) and (orientation: portrait) {
    .pressModal {
      overflow-y: scroll;
      padding-top: 16rem;
    }
    .pressModal .modal-close {
        z-index: 666;
        background: #df5051;
    }
}
  @media screen and (min-width:320px) and (max-width:1049px) and (orientation: landscape) {
  	.pressModal {
      overflow-y: scroll;
      padding-top: 16rem;
    }
    .pressModal .modal-close {
        z-index: 666;
        background: #df5051;
    }
}
</style>
